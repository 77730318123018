var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('h4',{staticClass:"mb-0"},[_vm._v(" Edit Buku ")]),_c('validation-observer',{ref:"validUpdate"},[_c('b-form',{staticClass:"mt-1",on:{"submit":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Kode Buku","label-for":"bookid"}},[_c('validation-provider',{attrs:{"name":"bookid","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"bookid","state":errors.length > 0 ? false : null,"placeholder":"Kode Buku"},model:{value:(_vm.dataStudent.bookid),callback:function ($$v) {_vm.$set(_vm.dataStudent, "bookid", $$v)},expression:"dataStudent.bookid"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Kategori","label-for":"category"}},[_c('validation-provider',{attrs:{"name":"category","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"category","state":errors.length > 0 ? false : null,"placeholder":"Kategori"},model:{value:(_vm.dataStudent.category),callback:function ($$v) {_vm.$set(_vm.dataStudent, "category", $$v)},expression:"dataStudent.category"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Judul","label-for":"title"}},[_c('validation-provider',{attrs:{"name":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"title","state":errors.length > 0 ? false : null,"placeholder":"Judul"},model:{value:(_vm.dataStudent.title),callback:function ($$v) {_vm.$set(_vm.dataStudent, "title", $$v)},expression:"dataStudent.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Tanggal Terbit","label-for":"publishdate"}},[_c('validation-provider',{attrs:{"name":"publishdate","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"publishdate","state":errors.length > 0 ? false : null,"placeholder":"Tanggal Terbit","type":"date"},model:{value:(_vm.dataStudent.publishdate),callback:function ($$v) {_vm.$set(_vm.dataStudent, "publishdate", $$v)},expression:"dataStudent.publishdate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Penulis / Pengarang","label-for":"author"}},[_c('validation-provider',{attrs:{"name":"author","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"author","state":errors.length > 0 ? false : null,"placeholder":"Penulis / Pengarang"},model:{value:(_vm.dataStudent.author),callback:function ($$v) {_vm.$set(_vm.dataStudent, "author", $$v)},expression:"dataStudent.author"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Penerbit","label-for":"author"}},[_c('validation-provider',{attrs:{"name":"published","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"published","state":errors.length > 0 ? false : null,"placeholder":"Penerbit"},model:{value:(_vm.dataStudent.published),callback:function ($$v) {_vm.$set(_vm.dataStudent, "published", $$v)},expression:"dataStudent.published"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Cover Depan Buku","label-for":"frontcover"}},[_c('validation-provider',{attrs:{"name":"frontcover","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"frontcover","state":errors.length > 0 ? false : null,"placeholder":"Cover Depan Buku"},model:{value:(_vm.dataStudent.frontcover),callback:function ($$v) {_vm.$set(_vm.dataStudent, "frontcover", $$v)},expression:"dataStudent.frontcover"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Cover Belakang Buku","label-for":"backcover"}},[_c('validation-provider',{attrs:{"name":"backcover","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"backcover","state":errors.length > 0 ? false : null,"placeholder":"Cover Belakang Buku"},model:{value:(_vm.dataStudent.backcover),callback:function ($$v) {_vm.$set(_vm.dataStudent, "backcover", $$v)},expression:"dataStudent.backcover"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',{staticClass:"text-right"},[_c('b-col',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"reset","variant":"outline-secondary"},on:{"click":function () { return _vm.$router.go(-1); }}},[_vm._v(" Cancel ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"submit","variant":"primary"}},[_vm._v(" Update ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }