<template>
  <b-card>
    <h4 class="mb-0">
      Edit Buku
    </h4>
    <validation-observer ref="validUpdate">
      <b-form class="mt-1" @submit.prevent="validationForm">
        <b-row>
          <b-col md="6">
            <b-form-group label="Kode Buku" label-for="bookid">
              <validation-provider #default="{ errors }" name="bookid" rules="required">
                <b-form-input id="bookid" v-model="dataStudent.bookid" :state="errors.length > 0 ? false : null"
                  placeholder="Kode Buku" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Kategori" label-for="category">
              <validation-provider #default="{ errors }" name="category" rules="required">
                <b-form-input id="category" v-model="dataStudent.category" :state="errors.length > 0 ? false : null"
                  placeholder="Kategori" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Judul" label-for="title">
              <validation-provider #default="{ errors }" name="title" rules="required">
                <b-form-input id="title" v-model="dataStudent.title" :state="errors.length > 0 ? false : null"
                  placeholder="Judul" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Tanggal Terbit" label-for="publishdate">
              <validation-provider #default="{ errors }" name="publishdate" rules="required">
                <b-form-input id="publishdate" v-model="dataStudent.publishdate"
                  :state="errors.length > 0 ? false : null" placeholder="Tanggal Terbit" type="date" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

          </b-col>
          <b-col md="6">
            <b-form-group label="Penulis / Pengarang" label-for="author">
              <validation-provider #default="{ errors }" name="author" rules="required">
                <b-form-input id="author" v-model="dataStudent.author" :state="errors.length > 0 ? false : null"
                  placeholder="Penulis / Pengarang" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Penerbit" label-for="author">
              <validation-provider #default="{ errors }" name="published" rules="required">
                <b-form-input id="published" v-model="dataStudent.published" :state="errors.length > 0 ? false : null"
                  placeholder="Penerbit" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Cover Depan Buku" label-for="frontcover">
              <validation-provider #default="{ errors }" name="frontcover" rules="required">
                <b-form-input id="frontcover" v-model="dataStudent.frontcover" :state="errors.length > 0 ? false : null"
                  placeholder="Cover Depan Buku" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Cover Belakang Buku" label-for="backcover">
              <validation-provider #default="{ errors }" name="backcover" rules="required">
                <b-form-input id="backcover" v-model="dataStudent.backcover" :state="errors.length > 0 ? false : null"
                  placeholder="Cover Belakang Buku" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

          </b-col>
        </b-row>
        <b-row class="text-right">
          <!-- submit and reset -->
          <b-col>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary" class="mr-1"
              @click="() => $router.go(-1)">
              Cancel
            </b-button>
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary">
              Update
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!-- <b-container> -->

    <!-- </b-container> -->
  </b-card>
</template>

<script>
import {
  // BContainer,
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  // BFormCheckbox,
  BFormInvalidFeedback,
  BForm,
  BButton,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import dataCountries from '@/assets/json/countries.json'
import dataProvinces from '@/assets/json/provinces.json'
import dataCities from '@/assets/json/cities.json'
import dataDistrics from '@/assets/json/districts.json'
import dataSubDistrics from '@/assets/json/subdistricts.json'

export default {
  name: 'DataSiswaEdit',
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    // BContainer,
    // BFormCheckbox,
    BForm,
    BButton,
    vSelect,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data: () => ({
    required,
    name: '',
    dataStudent: {
      bookid: '',
      title: '',
      category: '',
      publishdate: '',
      author: '',
      published: '',
      frontcover: '',
      backcover: '',
      active: '1',
      notes: '',
    },
  }),
  computed: {

  },
  created() {
    this.getStudentDetail()
  },
  methods: {
    async getStudentDetail() {
      try {
        const studentId = this.$route.params.id
        const response = await this.$http.get(`/librarybooks/${studentId}`)
        this.fillStudentData(response.data.data)
      } catch (err) {
        console.log(err)
      }
    },
    fillStudentData(data) {
      this.dataStudent.bookid = data.bookid
      this.dataStudent.title = data.title
      this.dataStudent.category = data.category
      this.dataStudent.publishdate = data.publishdate
      this.dataStudent.author = data.author
      this.dataStudent.published = data.published
      this.dataStudent.frontcover = data.frontcover
      this.dataStudent.backcover = data.backcover
      this.dataStudent.active = data.active
      this.dataStudent.notes = data.notes
    },
    validationForm() {
      this.$refs.validUpdate.validate().then(async success => {
        if (success) {
          // eslint-disable-next-line
          try {
            const studentId = this.$route.params.id
            const dataFormStudent = new FormData()
            dataFormStudent.append('_method', 'PUT')
            Object.keys(this.dataStudent).forEach(key => {
              dataFormStudent.append(key, this.dataStudent[key])
            })
            await this.$http.post(`/librarybooks/${studentId}`, dataFormStudent, {
              headers: {
                'content-type': 'multipart/form-data',
              },
            })
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Notification',
                  icon: 'InfoIcon',
                  text: 'Update Data Berhasil',
                  variant: 'success',
                },
              },
              {
                position: 'top-center',
              },
            )
            this.$router.push({ name: 'perpus-buku' })
          } catch (err) {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Notification',
                  icon: 'InfoIcon',
                  text: 'Update Data Siswa Gagal',
                  variant: 'danger',
                },
              },
              {
                position: 'top-center',
              },
            )
            console.log(err)
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-select.scss";
</style>
